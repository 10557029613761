import NumberField from '@pretto/bricks/components/form/NumberField'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import Slider from '@pretto/bricks/components/utility/Slider'
import { formatInputNumber, formatNumber, formatValuesWithData } from '@pretto/bricks/core/utility/formatters'
import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import { TimesBold } from '@pretto/picto'

import PropTypes from 'prop-types'
import { memo, useRef, useState } from 'react'

import * as S from './styles'

const getDurationDescription = duration => {
  if (duration < 11) return 'Vous pourrez voyager sur la Lune, mais il faudra une sacré capacité d’emprunt.'
  if (duration < 16) return 'L’astéroïde Apophis nous frôlera mais le risque de collision est écarté, ouf !'
  if (duration < 21) return 'Kylian Mbappé aura ramené 4 coupes du monde à la maison.'
  if (duration < 26) return 'Le Louis d’or aura 400 ans. C’était un placement malin avant l’immobilier.'
  return 'Justin Bieber sera officiellement quinquagénaire...'
}

const CONFIG = {
  duration: {
    description: (data, duration) => getDurationDescription(duration),
    title: 'Durée du prêt',
  },
  insuranceRate: {
    description: 'Le taux de l’assurance varie selon de nombreux critères comme votre âge, et votre état de santé.',
    title: 'Taux d’assurance',
    tooltip: [
      'L’assurance de prêt immobilier est une condition quasi obligatoire pour obtenir votre crédit : elle permet de vous couvrir dans le cas où il vous arriverait un aléa vous empêchant de rembourser votre prêt. C’est l’un des coûts les plus importants de votre crédit.',
      'Le taux d’assurance, fixé en fonction de l’organisme que vous choisissez, dépend de différents critères comme votre âge, votre état de santé, votre activité ou vos hobbies.',
    ],
  },
  payment: {
    description: 'C’est le montant que vous êtes capable de rembourser chaque mois.',
    title: 'Mensualité',
    tooltip: [
      'La mensualité correspond au montant que vous allez payer chaque mois pour le remboursement de votre prêt immobilier.',
      'Le montant de votre mensualité de crédit est calculé en premier lieu à partir du montant de votre emprunt, la durée sur laquelle vous souhaiter emprunter et le taux d’intérêt.',
      'Il est important de garder en tête que votre mensualité ne doit pas représenter plus du tiers de vos revenus (33%) : c’est ce que l’on appelle le taux d’endettement.',
    ],
  },
  principal: {
    description: 'C’est le montant que vous êtes capable de rembourser.',
    title: 'Montant emprunté',
    tooltip: [
      'Le montant de votre prêt correspond au montant total que vous souhaitez emprunter à la banque.',
      'En fonction du montant de l’apport personnel que vous souhaitez mettre, le montant que vous avez besoin d’emprunter varie. Il est souvent recommandé d’avoir un apport personnel équivalent à 10 % du prix du bien afin de couvrir les frais d’acquisition de votre achat (frais de notaire et garantie).',
    ],
  },
  rate: {
    description: ({ averageRate }) =>
      `Le taux moyen observé pour des crédits de cette durée est de ${formatNumber(averageRate, { suffix: '%' })}.`,
    title: 'Taux d’intérêt',
    tooltip: [
      'Le taux d’intérêt est le pourcentage que vous devez payer à la banque en échange de l’avance des fonds de votre prêt immobilier.',
      'Ce pourcentage, fixé par la banque, est conditionné par plusieurs critères : votre profil (situation, revenus, apport personnel), la durée de votre prêt, le montant que vous souhaitez emprunter mais aussi la négociation avec les banques.',
      'C’est donc un coût que vous avez tout intérêt à réduire puisqu’il impacte directement le coût global de votre crédit.',
    ],
  },
}

const Description = memo(({ children }) => (
  <S.Description>
    <Text size="x-small" variant="neutral-1-60">
      {children}
    </Text>
  </S.Description>
))
Description.propTypes = {
  children: PropTypes.node.isRequired,
}
Description.displayName = 'Description'

const Title = memo(({ children, tooltip }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <S.Title>
      <SubHeading>{children}</SubHeading>
      {tooltip && (
        <>
          <S.Icon onClick={handleOpen}>
            <Icon name="info" />
          </S.Icon>

          <Dialog isOpen={isOpen} onRequestClose={handleClose}>
            <DialogTemplate
              navbarProps={{
                iconLeft: TimesBold,
                iconLeftAction: handleClose,
                title: children,
              }}
            >
              <Content>
                {tooltip.map((p, i) => (
                  <S.Paragraph key={i}>{p}</S.Paragraph>
                ))}
              </Content>
            </DialogTemplate>
          </Dialog>
        </>
      )}
    </S.Title>
  )
})
Title.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.array,
}
Title.displayName = 'Title'

const CalculatorSlider = ({ data, numberFieldProps, sliderProps, type }) => {
  const [isFocused, setIsFocused] = useState(false)
  const fieldRef = useRef(null)
  useUpdateEffect(() => {
    if (isFocused && fieldRef.current) fieldRef.current.focus()
  }, [isFocused])

  const handleFieldRef = ref => (fieldRef.current = ref)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }

  const { description, title, tooltip } = formatValuesWithData(CONFIG[type], data, sliderProps.value)

  return (
    <div>
      <Title tooltip={tooltip}>{title}</Title>

      <S.Row>
        <S.RowColLeft>
          <Slider variant="primary-1" {...sliderProps} />
        </S.RowColLeft>

        <S.RowColRight>
          {isFocused ? (
            <NumberField ref={handleFieldRef} format="button" {...numberFieldProps} onBlur={handleBlur} />
          ) : (
            <S.NumberField onClick={handleFocus}>
              <div>{formatInputNumber(numberFieldProps.value)}</div>
              <S.NumberFieldSuffix>{numberFieldProps.suffix}</S.NumberFieldSuffix>
            </S.NumberField>
          )}
        </S.RowColRight>
      </S.Row>

      <Description>{description}</Description>
    </div>
  )
}

CalculatorSlider.defaultProps = {
  data: {},
}
CalculatorSlider.propTypes = {
  /** Data used to format elements like description. */
  data: PropTypes.object,
  /** Whether slider is disabled. If true, Slider won't be active. */
  disabled: PropTypes.bool,
  /** Value shown next to title. Must be set when `disabled` is `true`. */
  disabledValue: PropTypes.string,
  /** Props of component [`NumberField`](/#!/NumberField). */
  numberFieldProps: PropTypes.object,
  /** Toggle function for enabling / disabling the slider. If set, `disabled` will be the value of the toggle button. If not set, the toggle button will not appear. */
  onToggle: PropTypes.func,
  /** Props of component [`Slider`](/#!/Slider). */
  sliderProps: PropTypes.object,
  /** Slider's title. */
  type: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
}

export default memo(CalculatorSlider)
