import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { rho } from '@pretto/website/src/templates/simulateurCondenseVersionCalculette/lib/calculations'
import { MONTH_PER_YEAR } from '@pretto/website/src/templates/simulateurCondenseVersionCalculette/lib/constantValues'

export const principal = (payment, rate, duration, insuranceRate) => {
  if (rate === 0) return roundNumber(insurancePayment(payment, insuranceRate) + payment * (duration * MONTH_PER_YEAR))
  return roundNumber(
    payment / (rho(rate / 100 / MONTH_PER_YEAR, duration * MONTH_PER_YEAR) + insuranceRate / 100 / MONTH_PER_YEAR),
    100
  )
}

export const payment = (principal, rate, duration, insurancePayment = 0) => {
  if (rate === 0) return roundNumber(principal / (duration * MONTH_PER_YEAR))
  return roundNumber(principal * rho(rate / 100 / MONTH_PER_YEAR, duration * MONTH_PER_YEAR) + insurancePayment)
}

export const interests = (principal, payment, duration) => payment * (duration * MONTH_PER_YEAR) - principal

export const insurancePayment = (principal, insuranceRate) =>
  roundNumber((insuranceRate / 100 / MONTH_PER_YEAR) * principal)

export const insurance = (insurancePayment, duration) => insurancePayment * duration * MONTH_PER_YEAR

export const loanAmount = (interests, insurance) => interests + insurance
