import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import styled, { css, keyframes } from 'styled-components'

const cloudAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-37px); 
  }
`
const cloudAnimationDesktop = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-74px);
  }
`

export const Background = styled.div`
  background: ${({ theme }) => theme.colors.neutral4};
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.white} 10%,
    ${({ theme }) => theme.colors.neutral4}
  );
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`
export const Sticky = styled.div`
  position: fixed;
  top: ${g(10)};
  left: 0;
  right: 0;
  padding: ${g(2)} 0;
  background: ${({ theme }) => theme.colors.neutral4};
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(12)};
    padding: ${g(1)} 0;
  }

  ${({ isEntered }) =>
    isEntered &&
    css`
      transform: translateY(0);
    `}
`
export const Wrapper = styled(W)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
  }
`
export const Result = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    position: relative;
    flex: 0 0 50%;
  }
`
export const Aside = styled.div`
  flex: 0 0 50%;
  padding: ${g(3)} 0 ${g(3)} ${g(3)};
  position: relative;

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(5)} 0 ${g(5)} ${g(3)};
  }
`
export const Head = styled.div`
  padding-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: 0;
    position: relative;
  }
`
export const Heading = styled.div`
  margin: ${g(5)} 0 ${g(4)};
`
export const Title = styled.div`
  padding-bottom: ${g(1)};
  text-align: center;
`
export const Description = styled.div`
  padding: ${g(2)} 0;
`
export const Button = styled.div`
  display: flex;
`
export const Value = styled.div`
  text-align: center;
  line-height: 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    text-align: left;
  }
`
export const DisplayValueContainer = styled.div`
  margin-bottom: 2px;
`
export const DisplayValue = styled.div`
  font-size: ${g(5)};
  border: 1px dashed transparent;
  padding: ${g(0.5)};
  border-radius: ${g(0.5)};
  display: inline;
  line-height: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    font-size: ${g(7)};
  }
`
export const DisplayValueNumber = styled.span`
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 2px dashed ${({ theme }) => theme.legacy.colors.accent1.default};
  color: ${({ theme }) => theme.legacy.colors.neutral1.default};
  font-family: ${({ theme }) => theme.legacy.fonts.circular};
  font-size: inherit;
  outline: 0;
  padding: 0 ${g(0.5)};
  line-height: normal;
`
export const DisplayValueSuffix = styled.span`
  font-family: ${({ theme }) => theme.legacy.fonts.circular};
  color: ${({ theme }) => theme.legacy.colors.neutral1.default};
  margin-left: ${g(1)};
`
export const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
export const Animation = styled.div`
  width: ${g(20)};
  min-height: ${g(15)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(35)};
    min-height: ${g(25)};
  }
`
export const Cloud = styled.span`
  animation: ${cloudAnimation} 65s linear infinite;
  background: url(${getCloudinaryUrl('v1600345342/bricks/static-assets/icons/cloud.svg')}) no-repeat;
  height: ${g(3)};
  left: 0;
  position: absolute;
  right: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    animation-name: ${cloudAnimationDesktop};
    height: ${g(6)};
    background-size: auto 100%;
  }
`
export const CloudLeft = styled(Cloud)`
  animation-delay: -45s;
  bottom: 25%;

  @media screen and (min-width: ${breakpoints.laptop}) {
    animation-delay: -85s;
  }
`
export const CloudRight = styled(Cloud)`
  animation-delay: -15s;
  animation-duration: 60s;
  bottom: 15%;

  @media screen and (min-width: ${breakpoints.laptop}) {
    animation-delay: -25s;
  }
`
