import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

const HEIGHT = 8

export const Graph = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(${({ repeater }) => repeater}, ${g(8)}) 1fr;
  grid-template-rows: repeat(3, auto);
  grid-column-gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr repeat(${({ repeater }) => repeater}, ${g(19)}) 1fr;
    grid-column-gap: ${g(7)};
  }
`
const Position = styled.div`
  grid-column-start: ${({ index }) => index + 2};
`
export const BarContainer = styled(Position)`
  align-self: flex-end;
  grid-row-start: 1;
`
export const Bar = styled.div`
  height: ${({ ratio }) => g(ratio * HEIGHT)};
  background-color: ${({ theme, variant }) => theme.legacy.colors[variant]};
`
export const Separator = styled.div`
  grid-row-start: 2;
  grid-column: 1 / -1;
  border-bottom: 1px dashed ${({ theme }) => theme.legacy.colors.neutral1.fade(30)};
`
export const BubbleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${g(1)};
`
export const Bubble = styled.div`
  padding: ${g(0.5)} ${g(1)};
  font-size: 12px;
  font-family: ${({ theme }) => theme.legacy.fonts.circular};
  text-align: center;
`
export const Label = styled(Position)`
  grid-row-start: 3;
  text-align: center;
  line-height: 1;
  padding: 0 ${g(1)};
  margin-top: ${g(1)};
`
