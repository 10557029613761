import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Component = styled.div`
  padding: ${g(2)};
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
    border-radius: ${g(1)};
    border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
    overflow: hidden;
  }
`
export const Title = styled.div`
  margin-bottom: ${g(2)};
  text-align: center;
`
export const Details = styled.ul`
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: 0;
  }
`
export const Row = styled.li`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(2)} ${g(3)};
    margin-bottom: 0;
    line-height: 1;

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.legacy.colors.white.default};
    }
  }
`
export const Description = styled.div`
  a {
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
    text-decoration: underline;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    background-color: ${({ theme }) => theme.legacy.colors.white.default};
    padding: ${g(2)} ${g(3)} ${g(4)};
    border-radius: ${g(1)};
  }
`
export const Graph = styled.div`
  margin-top: ${g(3)};
`
