import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import isNil from 'lodash/isNil'
import lottie from 'lottie-web-light'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import house from './animations/house'
import * as S from './styles'

const FRAMES_PER_STEP = 25

const animations = { house }

const SvgAnimation = ({ step, type }) => {
  const nextFrame = step * FRAMES_PER_STEP
  const [player, setPlayer] = useState(null)
  const [currentFrame, setCurrentFrame] = useState(nextFrame)
  const animRef = useRef()

  useEffect(() => {
    const { current } = animRef
    if (current) {
      const loop = isNil(step)
      const animPlayer = lottie.loadAnimation({
        animationData: animations[type],
        animType: 'svg',
        autoplay: loop,
        loop,
        wrapper: current,
      })

      if (!loop) {
        setPlayer(animPlayer)
        animPlayer.goToAndStop(nextFrame, true)
      }
    }
  }, [])

  useUpdateEffect(() => {
    player.setSpeed(Math.abs((nextFrame - currentFrame) / FRAMES_PER_STEP))
    player.playSegments([currentFrame, nextFrame], true)
    setCurrentFrame(nextFrame)
  }, [step])

  return <S.Animation ref={animRef} />
}

SvgAnimation.propTypes = {
  /** Step where the animation should be. 0 is first step. If not set, animation will autoplay an loop. */
  step: PropTypes.number,
  /** Unique slug for the animation. The animation must be present in the animations folder as json. */
  type: PropTypes.oneOf(Object.keys(animations)).isRequired,
}

export default SvgAnimation
