import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
`
export const RowColLeft = styled.div`
  flex-grow: 1;
`
export const RowColRight = styled.div`
  width: 30%;
  margin-left: ${g(3)};
  text-align: right;
`
export const Description = styled.div`
  line-height: 1;
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: 0;
    width: 70%;
    padding-right: ${g(3)};
  }
`
export const DisabledValue = styled.div`
  grid-area: disabledValue;
`
export const Main = styled.div`
  grid-area: main;
`
export const MainContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
`
export const Icon = styled.div`
  cursor: pointer;
  padding: ${g(1)};
  color: ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};

  &:hover {
    color: ${({ theme }) => theme.legacy.colors.neutral1.fade(60)};
  }
`
export const Paragraph = styled.p`
  margin-bottom: ${g(1)};
`
export const NumberField = styled.div`
  font-family: ${({ theme }) => theme.legacy.fonts.circular};
  font-weight: 500;
  display: flex;
  border-radius: ${g(1)};
  border: 2px solid transparent;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  height: ${g(5)};
  line-height: ${g(5)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    font-size: ${ng(2) + 2}px;
    border: 0;
    height: ${g(6.5)};
    line-height: ${g(6.5)};
  }
`
export const NumberFieldSuffix = styled.div`
  margin-left: ${g(1)};
`
