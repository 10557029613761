export const SLIDERS = {
  duration: {
    default: 25,
    max: 30,
    min: 5,
    step: 1,
  },
  insuranceRate: {
    default: 0.34,
    max: 3,
    min: 0,
    step: 0.01,
  },
  payment: {
    default: 0,
    max: 10000,
    min: 0,
    step: 50,
  },
  principal: {
    default: 0,
    max: 1000000,
    min: 0,
    step: 10000,
  },
  rate: {
    default: 1.65,
    max: 5,
    min: 0,
    step: 0.01,
  },
}
