import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { LEGEND_LABELS } from '@pretto/website/src/config/rates'

export const getStep = principal => {
  if (principal < 200000) return 1
  if (principal < 300000) return 2
  if (principal < 500000) return 3
  if (principal < 800000) return 4
  return 5
}

export const getRates = (rates, duration) => {
  return rates.reduce((previous, item) => {
    if (item.duration === duration) {
      const formattedRates = ['ordinaire', 'bon', 'excellent'].map(key => ({
        label: LEGEND_LABELS[key].single,
        rate: roundNumber(item[key], 0.01),
      }))
      return formattedRates
    }

    return previous
  }, null)
}

export const getAverageRate = (rates, duration) => {
  const rate = rates.find(item => item.duration === duration).moyen
  return roundNumber(rate, 0.01)
}
