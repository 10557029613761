/* eslint-disable max-lines */
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { useScrollPosition } from '@pretto/bricks/assets/utility/useScrollPosition'
import { formatNumber, roundNumber } from '@pretto/bricks/core/utility/formatters'
import CalculatorPage from '@pretto/bricks/website/calculator/pages/CalculatorPage'

import { SimulateurAvanceAncienneVersion as SimulateurAvanceAncienneVersionView } from '@pretto/website/src/templates/simulateurAvanceAncienneVersion/views/SimulateurAvanceAncienneVersion/SimulateurAvanceAncienneVersion'
import { formatSimulators } from '@pretto/website/src/utilities'
import { trackAction } from '@pretto/website/src/utilities/tracking'

import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { getAverageRate, getRates, getStep } from './lib'
import * as calc from './lib/calc'
import { SLIDERS } from './lib/config'

const DEBOUNCE_RATE = 500
const RATE_VARIANT = {
  0: 'neutral-1-20',
  1: 'primary-2',
  2: 'primary-1',
}
const KEY_UP = 38
const KEY_DOWN = 40

const getRatesForDuration = (rates, duration) => getRates(rates, duration)
const getDefaultRate = (marketRates, duration) => {
  const { max, min } = SLIDERS.duration
  const d = duration < min ? min : duration > max ? max : duration
  return getAverageRate(marketRates, d)
}

const trigger = func => func()
const debounceFunc = debounce(trigger, 1000)

export const SimulateurAvanceAncienneVersion = ({ data, ...props }) => {
  const location = useLocation()

  const {
    rates: {
      data: { marketRates },
    },
    simulators,
  } = useStaticQuery(query)

  const { isMobile, breakpoint } = useBreakpoint()
  const { y } = useScrollPosition()
  const yPosition = useCallback(y, [y])
  const [principal, setPrincipal] = useState(SLIDERS.principal.default)
  const [sliderPrincipal, setSliderPrincipal] = useState(principal)
  const [payment, setPayment] = useState(SLIDERS.payment.default)
  const [sliderPayment, setSliderPayment] = useState(payment)
  const [duration, setDuration] = useState(SLIDERS.duration.default)
  const [sliderDuration, setSliderDuration] = useState(duration)
  const [debouncedDuration] = useDebounce(duration, DEBOUNCE_RATE)
  const defaultRate = getDefaultRate(marketRates, debouncedDuration)
  const [rate, setRate] = useState(defaultRate)
  const [sliderRate, setSliderRate] = useState(rate)
  const [insuranceRate, setInsuranceRate] = useState(SLIDERS.insuranceRate.default)
  const [sliderInsuranceRate, setSliderInsuranceRate] = useState(insuranceRate)
  const [step, setStep] = useState(0)
  const [debouncedStep] = useDebounce(step, DEBOUNCE_RATE)
  const [isMoreOptionsActive, setIsMoreOptionsActive] = useState(false)
  const [ratesForDuration, setRatesForDuration] = useState(getRatesForDuration(marketRates, duration))
  const isDesktop = !isMobile && breakpoint !== 'tablet'
  const isDetailed = isMoreOptionsActive || isDesktop

  useEffect(() => {
    setStep(1)
  }, [])

  useEffect(() => {
    const { max, min } = SLIDERS.duration
    if (debouncedDuration < min || !debouncedDuration) {
      setDuration(min)
      setSliderDuration(min)
      setRatesForDuration(getRatesForDuration(marketRates, min))
      return
    }
    if (debouncedDuration > max) {
      setDuration(max)
      setSliderDuration(max)
      setRatesForDuration(getRatesForDuration(marketRates, max))
      return
    }
    setSliderDuration(duration)
    setRatesForDuration(getRatesForDuration(marketRates, duration))

    if (!isMoreOptionsActive) {
      inputChangeHandler('rate', defaultRate)
    }
  }, [debouncedDuration])

  useEffect(() => {
    if (![duration, rate, insuranceRate].some(value => isNil(value))) {
      if (type === 'payment') {
        const payment = calcPayment()
        setPayment(payment)
      }
      if (type === 'principal') {
        const principal = calcPrincipal()
        setPrincipal(principal)
      }
    }
  }, [duration, rate, insuranceRate, isMoreOptionsActive])

  useEffect(() => {
    const step = getStep(principal)
    setStep(step)
  }, [principal])

  const {
    template: {
      calculator: { type },
    },
  } = data

  const track = (name, options = {}) => {
    trackAction(name, { pathname: location.pathname, ...options })
  }
  const debounceTrack = (...args) => debounceFunc(() => track(...args))

  const handleClickLink = () => {
    track('Calculator link clicked')
  }
  const handleCtaClick = () => {
    track('Calculator CTA clicked')
  }

  const interests = calc.interests(principal, payment, duration)
  const insurancePayment = calc.insurancePayment(principal, insuranceRate)
  const insurance = calc.insurance(insurancePayment, duration)
  const loanAmount = calc.loanAmount(interests, insurance)

  const calcPayment = (p = principal) => {
    const insuranceP = calc.insurancePayment(p, insuranceRate)

    return calc.payment(p, isDetailed ? rate : defaultRate, duration, isDetailed ? insuranceP : 0)
  }

  const calcPrincipal = (p = payment) =>
    calc.principal(p, isDetailed ? rate : defaultRate, duration, isDetailed ? insuranceRate : 0)

  const setters = {
    insuranceRate: {
      setter: setInsuranceRate,
      sliderSetter: setSliderInsuranceRate,
    },
    payment: {
      setter: setPayment,
      sliderSetter: setSliderPayment,
    },
    principal: {
      setter: setPrincipal,
      sliderSetter: setSliderPrincipal,
    },
    rate: {
      setter: setRate,
      sliderSetter: setSliderRate,
    },
  }

  const inputKeyDownHandler = (type, key, value) => {
    const { min, step } = SLIDERS[type]
    const { setter, sliderSetter } = setters[type]

    const isShift = !!window.event.shiftKey
    const increment = isShift ? step * 10 : step

    if (key === KEY_UP) {
      const newValue = roundNumber(value + increment, step)
      setter(newValue)
      sliderSetter(newValue)
      return newValue
    }
    if (key === KEY_DOWN) {
      const newValue = roundNumber(value - increment < min ? min : value - increment, step)
      setter(newValue)
      sliderSetter(newValue)
      return newValue
    }
  }

  const handleKeyDownPrincipal = e => {
    const key = e.keyCode
    const newPrincipal = inputKeyDownHandler('principal', key, principal)
    if (newPrincipal) {
      const payment = calcPayment(newPrincipal)
      inputChangeHandler('payment', payment)
    }
  }
  const handleKeyDownPayment = e => {
    const key = e.keyCode
    const newPayment = inputKeyDownHandler('payment', key, payment)
    if (newPayment) {
      const principal = calcPrincipal(newPayment)
      inputChangeHandler('principal', principal)
    }
  }
  const handleKeyDownDuration = e => {
    const { max, min, step } = SLIDERS.duration
    const key = e.keyCode

    if (key === KEY_UP) {
      const newValue = duration + step > max ? max : duration + step
      setDuration(newValue)
    }
    if (key === KEY_DOWN) {
      const newValue = duration - step < min ? duration : duration - step
      setDuration(newValue)
    }
  }
  const handleKeyDownRate = e => {
    const key = e.keyCode
    inputKeyDownHandler('rate', key, rate)
    if (!isMoreOptionsActive) {
      setIsMoreOptionsActive(true)
    }
  }
  const handleKeyDownInsuranceRate = e => {
    const key = e.keyCode
    inputKeyDownHandler('insuranceRate', key, insuranceRate)
  }

  const inputChangeHandler = (type, value) => {
    const { max, min } = SLIDERS[type]
    const { setter, sliderSetter } = setters[type]

    if (!value) {
      sliderSetter(min)
      setter(min)
      return
    }

    if (value > max) sliderSetter(max)
    else sliderSetter(value)

    setter(value)
  }

  const handleChangePayment = value => {
    debounceTrack('Calculator input payment changed', { payment: value })
    if (!value) {
      setPayment(0)
      setSliderPayment(0)
      setPrincipal(0)
      setSliderPrincipal(0)
      return
    }
    const principal = calcPrincipal(value)
    inputChangeHandler('principal', principal)
    inputChangeHandler('payment', value)
  }
  const handleChangePaymentSlider = value => {
    debounceTrack('Calculator slider payment changed', { payment: value })
    const principal = calcPrincipal(value)
    setPrincipal(principal)
    inputChangeHandler('payment', value)
  }
  const handleChangePrincipal = value => {
    debounceTrack('Calculator input principal changed', { principal: value })
    if (!value) {
      setPayment(0)
      setSliderPayment(0)
      setPrincipal(0)
      setSliderPrincipal(0)
      return
    }

    const payment = calcPayment(value)
    inputChangeHandler('payment', payment)
    inputChangeHandler('principal', value)
  }
  const handleChangePrincipalSlider = value => {
    debounceTrack('Calculator slider principal changed', { principal: value })
    const payment = calcPayment(value)
    setPayment(payment)
    inputChangeHandler('principal', value)
  }
  const handleChangeDuration = value => {
    debounceTrack('Calculator input duration changed', { duration: value })
    setDuration(value)
  }
  const handleChangeDurationSlider = value => {
    debounceTrack('Calculator slider duration changed', { duration: value })
    setDuration(value)
    setSliderDuration(value)
  }
  const handleChangeRate = value => {
    debounceTrack('Calculator input rate changed', { rate: value })
    inputChangeHandler('rate', value)
    if (!isMoreOptionsActive) {
      setIsMoreOptionsActive(true)
    }
  }
  const handleChangeRateSlider = value => {
    debounceTrack('Calculator slider rate changed', { rate: value })
    inputChangeHandler('rate', value)
    if (!isMoreOptionsActive) {
      setIsMoreOptionsActive(true)
    }
  }

  const handleChangeInsuranceRate = value => {
    debounceTrack('Calculator input insurance rate changed', { insurance_rate: value })
    inputChangeHandler('insuranceRate', value)
  }
  const handleChangeInsuranceRateSlider = value => {
    debounceTrack('Calculator slider insurance rate changed', { insurance_rate: value })
    inputChangeHandler('insuranceRate', value)
  }

  const handleToggleMoreOptions = () => {
    track('Calculator more options toggled', { toggled: !isMoreOptionsActive })
    setIsMoreOptionsActive(prev => !prev)
  }

  const detailsProps = {
    data: { insurance, insurancePayment },
    graphData: ratesForDuration.map(({ label, rate }, i) => ({
      displayValue: formatNumber(rate, { decimals: 2, suffix: '%' }),
      label,
      value: rate,
      variant: RATE_VARIANT[i],
    })),
    interests,
    onClickLink: handleClickLink,
    options: isDetailed
      ? {
          insuranceRate,
          loanAmount,
        }
      : null,
    payment,
    principal,
    rate: isDetailed ? rate : defaultRate,
  }

  const results = {
    payment: { onChange: handleChangePayment, onKeyDown: handleKeyDownPayment, value: payment },
    principal: { onChange: handleChangePrincipal, onKeyDown: handleKeyDownPrincipal, value: principal },
  }

  const resultProps = {
    ...results[type],
    step: debouncedStep,
    title: data.title,
    type,
    yPosition,
  }

  const principalSliderProps = useCallback(
    {
      numberFieldProps: {
        onChange: handleChangePrincipal,
        onKeyDown: handleKeyDownPrincipal,
        suffix: '€',
        value: principal,
      },
      sliderProps: {
        ...SLIDERS.principal,
        onChange: handleChangePrincipalSlider,
        value: sliderPrincipal,
      },
      type: 'principal',
    },
    [principal, sliderPrincipal]
  )
  const paymentSliderProps = useCallback(
    {
      numberFieldProps: {
        onChange: handleChangePayment,
        onKeyDown: handleKeyDownPayment,
        suffix: '€',
        value: payment,
      },
      sliderProps: {
        ...SLIDERS.payment,
        onChange: handleChangePaymentSlider,
        value: sliderPayment,
      },
      type: 'payment',
    },
    [payment, sliderPayment]
  )

  const durationSliderProps = useCallback(
    {
      numberFieldProps: {
        onChange: handleChangeDuration,
        onKeyDown: handleKeyDownDuration,
        suffix: 'ans',
        value: duration,
      },
      sliderProps: {
        ...SLIDERS.duration,
        onChange: handleChangeDurationSlider,
        value: sliderDuration,
      },
      type: 'duration',
    },
    [duration, sliderDuration]
  )

  const rateSliderProps = useCallback(
    {
      data: { averageRate: defaultRate },
      numberFieldProps: {
        onChange: handleChangeRate,
        onKeyDown: handleKeyDownRate,
        suffix: '%',
        type: 'decimal',
        value: rate,
      },
      sliderProps: {
        ...SLIDERS.rate,
        onChange: handleChangeRateSlider,
        value: sliderRate,
      },
      type: 'rate',
    },
    [rate, sliderRate, defaultRate]
  )

  const insuranceRateSliderProps = useCallback(
    {
      numberFieldProps: {
        onChange: handleChangeInsuranceRate,
        onKeyDown: handleKeyDownInsuranceRate,
        suffix: '%',
        type: 'decimal',
        value: insuranceRate,
      },
      sliderProps: {
        ...SLIDERS.insuranceRate,
        onChange: handleChangeInsuranceRateSlider,
        value: sliderInsuranceRate,
      },
      type: 'insuranceRate',
    },
    [insuranceRate, sliderInsuranceRate]
  )

  const simulatorsElements = useCallback(formatSimulators(simulators.nodes), [])

  const calculatorPage = {
    detailsProps,
    durationSliderProps,
    insuranceRateSliderProps,
    isMoreOptionsActive,
    onCtaClick: handleCtaClick,
    onToggleMoreOptions: handleToggleMoreOptions,
    paymentSliderProps,
    principalSliderProps,
    rateSliderProps,
    resultProps,
    simulators: simulatorsElements,
    type,
  }

  const headerComponent = useMemo(() => <CalculatorPage {...calculatorPage} />, [calculatorPage])

  return <SimulateurAvanceAncienneVersionView {...props} headerComponent={headerComponent} />
}

SimulateurAvanceAncienneVersion.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      calculator: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment SimulateurAvanceAncienneVersionTemplate on WpTemplate_SimulateurAvancancienneVersion {
    calculator {
      type
    }
  }
`

const query = graphql`
  query SimulateurAvanceAncienneVersion {
    rates(id: { ne: "dummy" }) {
      data {
        marketRates: market_rates_all_durations {
          duration
          bon
          excellent
          moyen
          ordinaire
        }
      }
    }
    simulators: allWpPost(filter: { template: { templateName: { eq: "Simulateur Condensé (version Calculette)" } } }) {
      nodes {
        id
        template {
          ... on WpTemplate_SimulateurCondensversionCalculette {
            simulator {
              type
            }
          }
        }
        title
        uri
      }
    }
  }
`
