import BarGraph from '@pretto/bricks/components/graphs/BarGraph'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { formatNumber, formatValuesWithData } from '@pretto/bricks/core/utility/formatters'
import Row from '@pretto/bricks/shared/components/Row'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from './styles'

const CONFIG = {
  insuranceRate: {
    label: 'Taux d’assurance',
    suffix: '%',
  },
  interests: {
    label: 'Coût des intérêts',
    suffix: '€',
  },
  loanAmount: {
    description: ({ insurance }) => `Dont assurance : ${formatNumber(insurance, { suffix: '€' })}`,
    label: 'Coût total du crédit',
    suffix: '€',
  },
  payment: {
    description: ({ insurancePayment }, isDetailed) =>
      isDetailed ? `Dont assurance : ${formatNumber(insurancePayment, { suffix: '€ / mois' })}` : '(hors assurance)',
    label: 'Votre mensualité',
    suffix: '€ / mois',
  },
  principal: {
    label: 'Montant emprunté',
    suffix: '€',
  },
  rate: {
    description: (data, isDetailed) =>
      !isDetailed && 'Ce taux correspond au taux moyen observé pour des crédit de cette durée.',
    label: 'Taux d’intérêt',
    suffix: '%',
  },
}

const CalculatorDetails = ({ data, graphData, interests, onClickLink, options, payment, principal, rate }) => {
  const simpleElements = [
    ['principal', principal],
    ['interests', interests],
    ['payment', payment],
    ['rate', rate],
  ]
  const detailedElements = options && [
    ['principal', principal],
    ['loanAmount', options.loanAmount],
    ['payment', payment],
    ['rate', rate],
    ['insuranceRate', options.insuranceRate],
  ]
  const elements = detailedElements || simpleElements
  return (
    <div>
      <ResponsiveSSR min="laptop">
        <S.Title>
          <SubHeading size="large">Détails de votre simulation</SubHeading>
        </S.Title>
      </ResponsiveSSR>
      <S.Component>
        <ResponsiveSSR max="laptop">
          <SubHeading size="large">Détails</SubHeading>
        </ResponsiveSSR>
        <S.Details>
          {elements.map(([type, value = 0], i) => {
            const { description, label, suffix } = formatValuesWithData(CONFIG[type], data, !!options)
            return (
              <S.Row key={i}>
                <Row label={label} value={<SubHeading size="small">{formatNumber(value, { suffix })}</SubHeading>} />
                {description && (
                  <Text variant="neutral-1-60" size="x-small" type="p">
                    {description}
                  </Text>
                )}
              </S.Row>
            )
          })}
        </S.Details>

        <S.Description>
          <Text variant="neutral-1-60" size="x-small" type="p">
            Pour connaître le meilleur taux pour votre profil,{' '}
            <Link onClick={onClickLink} href="https://app.pretto.fr">
              réalisez une simulation complète
            </Link>{' '}
            (gratuite et sans inscription).
          </Text>
          <S.Graph>
            <BarGraph data={graphData} />
          </S.Graph>
        </S.Description>
      </S.Component>
    </div>
  )
}

CalculatorDetails.defaultProps = {
  data: {},
}
CalculatorDetails.propTypes = {
  /** Data used to format elements like description. */
  data: PropTypes.object,
  /** Data used for [`BarGraph`](/#!/BarGraph) component. */
  graphData: PropTypes.array.isRequired,
  /** Interests euros. */
  interests: PropTypes.number.isRequired,
  /** On addition to navigating to the app via clicking the link, it triggers this function. */
  onClickLink: PropTypes.func,
  /** Options to include for the detauls. If empty, default values will be shown. */
  options: PropTypes.shape({
    insuranceRate: PropTypes.number.isRequired,
    loanAmount: PropTypes.number.isRequired,
  }),
  /** Payment in euros per month. */
  payment: PropTypes.number.isRequired,
  /** Amount of loan in euros. */
  principal: PropTypes.number.isRequired,
  /** Rate in percent. */
  rate: PropTypes.number.isRequired,
}

export default CalculatorDetails
