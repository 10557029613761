import Card from '@pretto/bricks/components/cards/Card'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const BarGraph = ({ data }) => {
  const max = data.reduce((previous, { value }) => {
    if (value > previous) return value
    return previous
  }, 0)
  return (
    <S.Graph repeater={data.length}>
      {data.map(({ displayValue, label, value, variant }, i) => (
        <Fragment key={i}>
          <S.BarContainer index={i}>
            <S.BubbleContainer>
              <Card format="small-radius" arrow="bottom" arrowSize={0.5} variant={variant}>
                <S.Bubble>{displayValue}</S.Bubble>
              </Card>
            </S.BubbleContainer>
            <S.Bar ratio={value / max} variant={variant} index={i} />
          </S.BarContainer>
          <S.Label index={i}>
            <Text size="x-small">{label}</Text>
          </S.Label>
        </Fragment>
      ))}
      <S.Separator />
    </S.Graph>
  )
}

BarGraph.propTypes = {
  /** Each item represent a bar. */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default memo(BarGraph)
