import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper } from '@pretto/bricks/components/layout/Wrapper/styles'
import styled from 'styled-components'

export const Page = styled(Wrapper)`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(6)};
  }
`
export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ${g(3)} 0;
`
export const OptionsLabel = styled.div`
  margin-right: ${g(2)};
`
export const Button = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(2)};
  margin-left: ${g(2)};
  margin-right: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(6)};
    margin-bottom: ${g(10)};
  }
`
export const Hero = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    align-items: stretch;
  }
`
export const Result = styled.div`
  flex: 1 1 50%;
`
export const Sliders = styled.div`
  flex: 1 1 50%;
`
export const Slider = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(1)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`
export const Details = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(4)};
`
export const Simulators = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  margin-top: ${g(2)};
  margin-bottom: ${g(2)};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(4)};
    margin-bottom: ${g(4)};
    padding: ${g(4)} 0;
  }
`
export const SimulatorsTitle = styled.div`
  text-align: center;
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(5)};
  }
`
